import React from 'react';

const SecuritySection = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>Keep payment info and business data safe</h2>
                <p>Flobux Payments is PCI compliant and supports 3D Secure checkouts.</p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <h3>3D Secure checkouts</h3>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <h3>PCI-compliant servers</h3>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <h3>Payment data encryption</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecuritySection;
