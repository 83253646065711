import React from 'react';

const ContactUs = () => {
    return (
        <div className="contact-us-section" id='contact-us'>
            <div className="contact-us-container container row m-0">
                <div className='contact-us-head'>
                    <h1 >We're Here for You</h1>
                    <h3 className='mb-3'>24/7 Dedicated Support</h3>
                    <p>Our support team is available around the clock to assist you with any questions or issues, ensuring a smooth and hassle-free experience.</p>
                </div>
                <div className='contact-us-left col-lg-6 col-md-12 col-sm-12 col-12'>
                    <h2 className="contact-us-title">Get In Touch</h2>
                    <h1 className="contact-us-heading">Contact Us</h1>
                    <p className="contact-us-subtitle">Have questions or suggestions? Reach out to us.</p>
                    <p className="contact-us-subtitle">Enter your email and message here.</p>
                </div>
                <div className='contact-us-right col-lg-6 col-md-12 col-sm-12 col-12'>
                    <form className="contact-us-form">
                        <div className="form-row">
                            <input type="text" name="subject" placeholder="Subject" />
                            <input type="text" name="name" placeholder="Your Name" />
                        </div>
                        <div className="form-row">
                            <input type="email" name="email" placeholder="Your Email" />
                            <input type="text" name="phone" placeholder="Phone Number" />
                        </div>
                        <textarea name="message" placeholder="Your Message"></textarea>
                        <div className='submit-button'>
                            <button type="">Send Your Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
