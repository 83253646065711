import React from 'react';
import { FaUser, FaShoppingCart, FaSearch, FaUserAlt } from 'react-icons/fa';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar">
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="#">
          <img src="/images/logo.svg" alt="Brand Logo" className="brand-logo" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <a className="nav-link" href="#">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#why-choose-us">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#our-services">Our Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Careers</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact-us">Contact Us</a>
            </li>
          </ul>
          <div className="header-login-lets-talk gap-4">
            <a href="#" className="nav-link"> Log in</a>
            <button className="btn btn-light ms-2 start-free-trial-button">Let's talk</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
