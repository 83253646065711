import React from 'react'
import Header from './Header'
import HeroSection from './HeroSection'
import SecuritySection from './SecuritySection'
import WhyChooseUs from './WhyChooseUs'
import Secction3 from './Section3'
import PricingPlans from './PricingPlans'
import FreeTrial from './FreeTrial'
import ContactUs from './ContactUs'
import Footer from './Footer'

const App = () => {
  return (
    <>
      <div className=''>
        <Header/>
        <HeroSection/>
        <WhyChooseUs/>
        <SecuritySection/>
        <Secction3/>
        {/* <PricingPlans/> */}
        <ContactUs/>
        {/* <FreeTrial/> */}
        <Footer/>
      </div>
    </>
  )
}

export default App
