import React from 'react';

const HeroSection = () => {
  return (
    <section className="hero-section ">
      <div className='container hero-section-items'>
        <div className="hero-text">
          <h5>FLOBUX PAYMENTS</h5>
          <h1>Revolutionizing Digital Payments</h1>
          <p>Seamless, Secure, and Swift Transactions on the Blockchain</p>
          <span>Discover the future of online transactions with Flobux, a blockchain-based payment solution that ensures your money is always safe, fast, and accessible. Join us in transforming how the world moves money.</span>
          {/* <button className="btn btn-dark start-free-trial-button py-3 font-extrabold">Log in to set up Shopify Payments</button><br/> */}
          {/* <a href="#">Don't have a Shopify store? Start free, then get your first month for ₹20.</a> */}
        </div>
        <div className="hero-image">
          <img src="/images/hero.jpeg" className='img-fluid' alt="Man holding a product" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
