import React from 'react';

const Secction3 = () => {
  return (
    <>
      <section className="alignment-two container mt-5">
        <div className="alignment-one-text">
          <h5>Simple, Intuitive, Powerful</h5>
          <h2>Designed with You in Mind</h2>
          <p>Our platform is designed to be user-friendly and intuitive, making it easy for anyone to send and receive money with just a few clicks.</p>
        </div>
        <div className="alignment-one-image">
          <img src="/images/sec2D.png" alt="Man holding a product" />
        </div>
      </section>
      <section className="alignment-one container">
        <div className="alignment-one-text">
          <h5>Payments Without Borders</h5>
          <h2>Worldwide Reach, Local Feel</h2>
          <p>Flobux brings the world closer together by enabling seamless cross-border transactions. Enjoy global accessibility with the security of local banking.</p>
        </div>
        <div className="alignment-one-image">
          <img src="/images/sec2E.jpeg" alt="Man holding a product" />
        </div>
      </section>
    </>
  );
};

export default Secction3;
