import React from 'react';

const WhyChooseUs = () => {
  return (
    <>
    <div className='why-choose-us'>
      <h2 className='why-choose-us-heading' id='why-choose-us'>Why Choose Flobux?</h2>
      <section className="alignment-one container">
        <div className="alignment-one-text">
          <h5>Trust and Innovation in Every Transaction</h5>
          <h2>The Ultimate Digital Payment Experience</h2>
          <p>Experience the power of blockchain technology with unparalleled security, transparency, and efficiency. Flobux is designed for users who demand the best in digital payments.</p>
        </div>
        <div className="alignment-one-image">
          <img src="/images/sec2A.jpeg" alt="Man holding a product" />
        </div>
      </section>
      <section className="alignment-two container">
        <div className="alignment-one-text">
          <h5>Your Money, Our Priority</h5>
          <h2>Advanced Blockchain Security Measures</h2>
          <p>With Flobux, your transactions are protected by state-of-the-art encryption and decentralized verification, ensuring your funds are always safe from fraud and hacking.</p>
        </div>
        <div className="alignment-one-image">
          <img src="/images/sec2B.jpeg" alt="Man holding a product" />
        </div>
      </section>
      <section className="alignment-one container">
        <div className="alignment-one-text">
          <h5>Speed that Matters</h5>
          <h2>Instant Payments, Anytime, Anywhere</h2>
          <p>Say goodbye to waiting times. Flobux leverages blockchain technology to provide instant transaction processing, no matter where you are in the world.</p>
        </div>
        <div className="alignment-one-image">
          <img src="/images/sec2C.png" alt="Man holding a product" />
        </div>
      </section>
    </div>

    </>
  );
};

export default WhyChooseUs;
